
import Vue from "vue";

export default Vue.extend({
  props: {
    title: { type: String },
    btnText: { type: String }
  },
  data() {
    return {};
  },
  methods: {}
});
