
import Vue from 'vue';
import { myConfirm } from '@/utils/confirm';
import Ellipsis from '@/components/Ellipsis.vue';

export default Vue.extend({
  components: { Ellipsis },
  props: {
    item: { type: Object },
    selected: { type: Boolean },
    displayControl: { type: Boolean },
  },
  computed: {
    creatorName(): string {
      return this.item.createNickName;
    },
  },
  data() {
    return {};
  },
  methods: {
    async getCreatorName(id) {

    },
    selectCatalogue() {
      this.$emit('onSelect', this.item);
    },
    editItem() {
      this.$emit('onEditClick', this.item);
    },
    deleteItem() {
      myConfirm(this, {
        text: '确认删除此目录吗?',
        resolve: () => {
          this.$emit('onDeleteClick', this.item);
        },
      });
    },
  },
});
