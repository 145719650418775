import { render, staticRenderFns } from "./create-catalogue-modal.vue?vue&type=template&id=5ff07df0&scoped=true&"
import script from "./create-catalogue-modal.vue?vue&type=script&lang=ts&"
export * from "./create-catalogue-modal.vue?vue&type=script&lang=ts&"
import style0 from "./create-catalogue-modal.vue?vue&type=style&index=0&id=5ff07df0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff07df0",
  null
  
)

export default component.exports