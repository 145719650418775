
import { ElForm } from 'element-ui/types/form';
import Vue from 'vue';

export default Vue.extend({
  props: {
    visible: { type: Boolean },
    title: { type: String },
    defaultInputValue: { type: String },
  },
  data() {
    return {
      ruleForm: {
        name: '',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
      inputValue: '',
      errorHint: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
    },
    onInput(e) {
      this.inputValue = e;
    },
    handleConfirm() {
      (this.$refs.ruleForm as ElForm).validate((valid) => {
        if (valid) {
          this.$emit('confirm', this.ruleForm.name);
          this.closeModal();
        } else {

        }
      });
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          (this.$refs.ruleForm as ElForm)?.resetFields();
          this.ruleForm.name = this.defaultInputValue;
        }
      },
    },
  },
});
